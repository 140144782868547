import { Grade } from '@mui/icons-material';
import React, { useState } from 'react';
import GradButton from './../../Util/GradButton';
const articles = [
	{
		"title": "A Guide To Being a New Car Owner",
		"date": "December 2022",
		"author": "Arin Shrivastava",
		"topic": "Customer service",
		"image": "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2018/01/why-kei-51455-2400x1440.jpg",
		"content": 
		<div>
			<p className="subheading2W">
				Congratulations! You are now the owner of an electric vehicle! What’s next? Whether this is your first time owning a car or your first time owning an electric car, there are many things that are helpful to know as you start your journey. That’s why we’re here to answer every question you have, and some that you don't.
				<br /><br />
				Maintenance
				<br /><br />
				If you want your car to perform well for an extended period of time, you need to take good care of it. The maintenance that a car requires can vary based on the make and model, and luckily for you, electric vehicles require less maintenance than their gas-powered counterparts. There are still some things that are required of both types, however. You should still be keeping an eye on your cooling systems, braking systems, wiper fluid, and all of your lights, and if something isn’t working properly, then get it fixed as soon as possible. Your tires will also wear out over time, so make sure to check regularly to see whether they need to be replaced. There are also certain maintenance features that are unique to electric cars. In order to maximize the efficiency and lifespan of your car battery, you should avoid extreme temperatures, fully charging or depleting your battery, and overusing fast charging stations. While this may seem like a lot of rules to follow, be thankful that you don’t have to go through everything that gas cars do. I mean, you never have to change your car’s oil again!
				<br /><br />
				Warranties
				<br /><br />
				If you bought your car from Dynamo, then we’ve got you covered for the first 60 days or 1000 miles driven. Your specific warranty may vary, but they all guarantee that the manufacturer or seller (in this case Dynamo) will fix certain defects and damages in your car during a certain time frame. They won’t cover normal wear and tear or regular maintenance, nor will they cover damages that come through highly abnormal use such as offroading. There are several different types of warranties that you can get. A comprehensive warranty covers every part of your car, while some other warranties only cover certain parts of your car and may last for a different amount of time. Normal warranties are included in the car price, but extended warranties can be purchased to cover your car after the standard expiration date. It is important to know what your warranty covers and how long it lasts so you know how and when you can get it fixed by the dealer.
				<br /><br />
				Insurance
				<br /><br />
				Car insurance has a much broader scope than a warranty. Depending on where you live, the law may require you to have some form of auto insurance, but it’s probably a good idea to get anyways. In exchange for paying monthly premiums, the insurance company will cover damages that occur during accidents or other situations, as well as any damages you may have caused. You will have to pay a certain amount of money known as a deductible towards any expenses before the insurance company will cover any costs. The price of your insurance will depend on your driving history, age, the car you drive, the insurance company, how much your plan covers, the size of your deductible, and more. Liability insurance, which covers any damages or injuries caused to others in a crash that you caused, is required by law in most states, but your plan can also cover certain events and damages, such as collision insurance and personal injury insurance. A comprehensive auto insurance plan covers all of these, but will also be the most expensive. Make sure to research and compare different insurance plans from different companies to find the one that’s best for you, but no matter which way you end up going, car insurance is something that you definitely need.
				<br /><br />
				Charging
				<br /><br />
				One of the biggest differences between owning an electric car as opposed to a gas-powered one is the charging. If you’re worried about not being able to find charging stations, then you can rest assured knowing that it won’t be a problem. Not only can you charge your car at home—something that is much more difficult to do with gasoline—you will be able to find additional charging stations everywhere, be it at malls and stores, offices, gas stations, or public charging stations, and more are constantly being built. Not all chargers are built equally, however. Alternating current, or AC, is what you are likely to find at most charging stations, and what most people choose to install in their homes, as it gives the car a long-lasting charge. Direct current, or DC, charges the car faster and gives it more power, but lasts for a shorter amount of time, and you’re more likely to find it at a gas station. While AC can take 1 to 20 hours to charge a car, DC can do the same in 7 minutes to 2 hours, depending on a number of factors such as your car’s battery size. Faster DC charging tends to also be more expensive than public AC charging, although charging at home is the cheapest option. Different types of charging also require different types of cables. Mode 2 cables can connect to household outlets, Mode 3 cables are the standard for AC charging, and Mode 4 is used for DC charging. While this may seem like a lot, once you know how to charge your car, it will become much simpler than any gas car you have owned.
			</p> 
		</div>,
		"urlslug": "1002",
		"tags" : ["Customer Service", 'Cars'],
		"color": ""
	},
	{
		"title": "On the Fence About Electric Vehicles?",
		"date": "December 2022",
		"author": "Arin Shrivastava",
		"topic": "General",
		"image": "https://cdn2.system1.com/eyJidWNrZXQiOiJvbS1wdWItc3RvcmFnZSIsImtleSI6IndhbGxldGdlbml1cy93cC1jb250ZW50L3VwbG9hZHMvMjAyMS8wNC9FbGVjdHJpYy1jYXItdnMtZ2FzLWNvc3RzLXNjYWxlZC5qcGciLCJlZGl0cyI6eyJ3ZWJwIjp7InF1YWxpdHkiOjQwfSwicmVzaXplIjp7ImZpdCI6ImNvdmVyIiwiYmFja2dyb3VuZCI6eyJyIjowLCJnIjowLCJiIjowLCJhbHBoYSI6MX0sIndpZHRoIjo5MDAsInBvc2l0aW9uIjoidG9wIn19fQ==",
		"content": 
		
		<div>
			<p className="subheading2W">
			If you’re on this website, you might be wondering whether you should buy an electric car. After all, most cars are gas-powered. But there are several advantages to buying an electric vehicle, or EV, regardless of your age, income, location, and more.
			<br /><br />
			First of all, they simply perform at a higher level than your average gas car. Be it faster acceleration times, smooth handling, or the lack of noise from an engine, EVs are more fun and enjoyable to drive. There is no way to describe the feeling you get driving one of these, but it is obvious to anyone who has driven an electric car. Replacing the engine with a battery pack also lowers the car’s center of gravity and provides better structural stability, which helps make the car safer to drive.
			<br /><br />
			Many people worry about the cost of electric cars, but they are much less expensive in the long run. Imagine never having to buy gas again. Imagine that instead of driving to a gas station and having to deal with long lines and dirty or malfunctioning pumps, you can instead just charge your car at home the same way you do with your phone. That’s how it is when you have an electric vehicle. With a single charge, your car can travel hundreds of miles, and public charging stations are abundant if you ever need a recharge. EVs also have lower maintenance costs, and the government will even help you out with tax incentives. So while some of these cars may have a slightly higher up-front cost, buying one would still be the better financial decision.
			<br /><br />
			Finally, there is of course the fact that EVs help out the environment. Traditional gas-powered cars release incredible amounts of pollution into the atmosphere, which has contributed to the lingering threat of climate change. While it may seem like there’s not much you can do about it, any step can go a long way towards helping to save our planet. Electric vehicles don’t release greenhouse gases into the air because they don’t burn gasoline and other fossil fuels. Cutting the amount of greenhouse gas in the atmosphere will help slow down global warming and create a better future for everyone living on Earth.
			<br /><br />
			</p>
		</div>,
		"urlslug": "1001",
		"tags" : ["R&D", 'Cars'],
		"color": ""
	},
	{
		"title": "Charging My Electric Cars",
		"date": "December 2022",
		"author": " Surya Ani",
		"topic": "Technology",
		"image": "https://hips.hearstapps.com/hmg-prod/images/electric-car-recharging-in-charging-station-royalty-free-image-1585168794.jpg?crop=1.00xw:1.00xh;0,0&resize=1200:*",
		"content": 
		
		<div>
			<p className="subheading2W">
			With the expanse of the electrical vehicle industry, questions have risen on the efficiency of charging stations. Will my car just stop in the middle of the road when I lose charge? How long does it take to charge my car? Are there enough charging stations in my area for me to effectively travel without any worries?
			<br /><br />
These are some questions that arise, compelling individuals to stick with gas-powered vehicles. But is this the case?
<br /><br />
To this date, there are close to 2,000 Tesla charging stations. Now you might ask if these are Tesla charging stations, how would I charge electric vehicles created by other manufacturers? Tesla supercharging stations usually allow other electrical vehicles to charge at their ports due to similar uses of technology and techniques.
<br /><br />
In terms of the duration it takes to charge these electric vehicles, it can take from a mere 30 minutes to more than 12 hours. That’s a pretty big range, but it is important to understand that manufacturers have created specific products designed to address this inconvenience. In-home charging stations have become more prevalent in the years, providing you with a fully-charged vehicle while it's connected to the home charging station.
<br /><br />
In conclusion, it should be noted that although numerous individuals proclaim potential drawbacks to the use of eclectic cars, car manufacturers and technological companies have developed solutions to address these issues. The electric vehicle industry is becoming far more capable and advanced in the years to come as better techniques are used to increase battery life and the speed at which it gets charged.
<br /><br />
Switch to electric vehicles today!
<br /><br />

			</p>
		</div>,
		"urlslug": "1000",
		"tags" : ["Technology", "Cars"],
		"color": ""
	},
	{
		"title": "Purchasing a Car from Dynamo",
		"date": "December 2022",
		"author": "Surya Ani",
		"topic": "Customer Service",
		"image": "https://blog.usccreditunion.org/hs-fs/hubfs/buying%20car.jpg?width=1290&height=848&name=buying%20car.jpg",
		"content": 
		
		<div>
			<p className="subheading2W">
				Purchasing a car can be a hassle and stressful situation. But our team at Dynamo ensures that all your needs are met to give you a swift experience in buying the car of your dreams!
				<br /><br />
				To start your exciting journey in buying your car, access dynamo.pulkith.com and browse the diverse array of vehicles offered. Once you find a car that you like, press the “Reserve” button and fill in the necessary details. After successfully providing the needed details, you will get an email with a confirmation number ensuring the reservation of your potential car for the next 30 days.
				<br /><br />
				After receiving an email from Dynamo, go to our location and show the front desk your confirmation email. Our store associate will give you a tour of your possible future vehicle, answering any questions that you may have. You have the option of test driving these vehicles, giving you a relative feel of the car accessories and its basic feel.
				<br /><br />
				Next, after you make a decision in purchasing the vehicle, our staff will help you fill out the necessary documentation, such as financing options and insurance policies, and will check your credit score for a pleasant, efficient experience for you.
				<br /><br />
				To conclude, after the successful reviewing of your profile and the completion of necessary documents, you can take your electric vehicle home!
				<br /><br />
				Please make sure to stop by The Plug, our accessory store, that would provide you with the needed equipment, such as home-charging stations and batteries, which would make your electric vehicle the car of your dreams.
				<br /><br />
			</p>
		</div>,
		"urlslug": "1003",
		"tags" : ["Customer Service", "Cars"],
		"color": ""
	},
]

export const getArticle = (id) => {
	let ret = articles[articles.length - 1];
	articles.forEach(article => {
		if(article.urlslug && article.urlslug === ""+id) {
			ret = article;
			return;
		}
	})
	return ret;
}

export const getRecentArticles = () => {
	return [
		1000,
		1001,
		1002
	]
}
export const getAllArticles = () => {
	return [
		1000,
		1001,
		1002,
		1003
	]
}