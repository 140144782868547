
import React, { useState } from 'react';
import '../../css/Team.css';
import PageHeader from './../Util/PageHeader';
const Team = () => {

	let exec = [], research = [], mad = [], eo = []
	exec.push(<StaffPerson name="Arin Shrivastava" title="CEO" image="https://media.licdn.com/dms/image/C5603AQH_Q_NCN9bk6g/profile-displayphoto-shrink_800_800/0/1656542483379?e=2147483647&v=beta&t=HboAbzY9HnlomNoD1BaC18vwzQahlL2pNYbg46zLD6g"/>)
	exec.push(<StaffPerson name="Surya Ani" title="CFO" image="https://media.licdn.com/dms/image/D5603AQGcwJb4pGM4dg/profile-displayphoto-shrink_400_400/0/1669154091268?e=1683158400&v=beta&t=K5u1ILa1SQ0ICMlo15ChHyaPhfXx5bDtlizDmKdzgJw"/>)
	exec.push(<StaffPerson name="Pulkith Paruchuri" title="CTO" image="https://media.licdn.com/dms/image/C4E03AQGW2M9Jtw8asw/profile-displayphoto-shrink_800_800/0/1660550290903?e=2147483647&v=beta&t=2ridwlmkwb7vy3vaV3rdAjjNqCRvWp0KOf3X0RnHRBM"/>)

	research.push(<StaffPerson name="Bhavanin Sudula" title="Lead Sales Representative" image="https://media.licdn.com/dms/image/D4E03AQFXR4L4skb2iw/profile-displayphoto-shrink_400_400/0/1675045644157?e=1683158400&v=beta&t=RMyE9IDPSZjK4KDBzVG4gfaYcOhq-NCwNvO53fcY9zE" />)
	research.push(<StaffPerson name="Sharvi Save" title="Sales Representative" image="https://media.licdn.com/dms/image/C5603AQEa7LPVULBAtA/profile-displayphoto-shrink_400_400/0/1658243767507?e=1683158400&v=beta&t=QJ5HxRSirqyJCV3QcUgKOc8mhVR3915lydy-PtsaOMM" />);
	research.push(<StaffPerson name="Jiya Mody" title="Sales Representative" image="https://media.licdn.com/dms/image/D4D03AQFHCcjInb17xA/profile-displayphoto-shrink_400_400/0/1670040506556?e=1683158400&v=beta&t=J25u2RjNChZrc7YzmcA6JjJcnVnpq7isMCOdLGzzKtE" />)
	research.push(<StaffPerson name="Dino Paul" title="Sales Representative" image="https://media.licdn.com/dms/image/C5603AQGMhuhlHvqkew/profile-displayphoto-shrink_400_400/0/1517784272780?e=1683158400&v=beta&t=Zs_XljpeEqWHuc1b7L-VFvwkTSDmU0nUDuUI_rhXOrs" />)
	research.push(<StaffPerson name="Poojan Kedia" title="Sales Representative" image="https://media.licdn.com/dms/image/D4E03AQE0ISfl6BZ2kQ/profile-displayphoto-shrink_400_400/0/1638739745551?e=1683158400&v=beta&t=cOSiKalLlyz3jlN7QYi9DItsCZbRgombhe6JKiHAOGg" />)


  
	mad.push(<StaffPerson name="Shreyas Pattabi" title="Maintenance" image="https://media.licdn.com/dms/image/D5603AQEcvVw77oceBQ/profile-displayphoto-shrink_400_400/0/1675615027051?e=1683158400&v=beta&t=oCTvYRWvEetWNdFSu-bMZ4xW95VnlwuTKcN3vby8WOI" />)
	mad.push(<StaffPerson name="Vamsi Putti" title="History Reports" image="https://media.licdn.com/dms/image/C5603AQGuGvR0Pj179g/profile-displayphoto-shrink_400_400/0/1651014109038?e=1683158400&v=beta&t=cQWeZORrr59OLqBaDVSVfMu-vQm8HapGBuSijzIKydc" />)
	mad.push(<StaffPerson name="Akshaya Sadanala" title="Assessments" image="https://media.licdn.com/dms/image/D5603AQGMAlTzvkbT5w/profile-displayphoto-shrink_400_400/0/1665952473547?e=1683158400&v=beta&t=U1STCpcGA-dS3N5nCYp7QZuJpADaRfGO1p-I3UH6H_A" />)

	eo.push(<StaffPerson name="Rithika Duvva" title="Finance Lead" image="https://media.licdn.com/dms/image/D4E03AQGZ8pWBGQiY4Q/profile-displayphoto-shrink_400_400/0/1643512872818?e=1683158400&v=beta&t=P-u4kuQIJrAkKfyOiX0UZRiXxQsabOlCYNJQLeCeP6s" />);
	eo.push(<StaffPerson name="Prayag Peram" title="Outreach Lead" image="https://media.licdn.com/dms/image/C4E03AQH8rYRhTj1ihQ/profile-displayphoto-shrink_400_400/0/1633140458898?e=1683158400&v=beta&t=_5Rnz6UP7ZsmUqgKYqQ2Y4phGMZKKYkWRouGLMKER4E" />)
	eo.push(<StaffPerson name="Eshaaniwani Shrivasta" title="Finance" image="https://media.licdn.com/dms/image/D4E03AQFtlTk_hs0JZw/profile-displayphoto-shrink_400_400/0/1675035291199?e=1683158400&v=beta&t=IwQ-2yFbMq9337vFaoPqCnaewv4raKql3T4Wp68_uSo" />)
  


  return (
    <>
		 	
		 	<div className="team">
				<div className="leftwrap">
					<h1 className='heading gradtext thinfont'>We're ready to serve you with industry-leading experience</h1>
				</div>

				<div className="wrapper">
					<div className="department">
						<h2 className="text shSmall ">Management</h2>
						<div className='row'>
							{exec}
						</div>
						
					</div>
					<div className="department">
						<h2 className="text shSmall ">Sales Representatives</h2>
						<p className="subsubheading italic thinfont">We work with you to help choose the best car for your needs at your price point</p>
						<div className='row'>
							{research}
						</div>
					</div>
					<div className="department">
						<h2 className="text shSmall ">Vehicle Handlers</h2>
						<p className="subsubheading italic thinfont">Vehicle Handlers help with inspection, history, asssessment and more.</p>
						<div className='row'>
							{mad}
						</div>
					</div>
					<div className="department">
						<h2 className="text shSmall ">Support</h2>
						<p className="subsubheading italic thinfont">Support staff helps with critical tasks across the board</p>
						<div className='row'>
							{eo}
						</div>
					</div>
				</div>


			</div>

    </>
  );
}

const StaffPerson = (props) => {
	return(<>
		<div className="staffperson">
			<div className="img">
				<div className="maxsizeimgwrapper">
					<img src={props.image} alt="placeholder" className="maxsizeimg" style={{borderRadius: "1rem 1rem 0rem 0rem"}}/>
				</div>
			</div>
			<div className='content'>
				<h2 className="name">{props.name}</h2>
				<h3 className="title">{props.title}
				
				</h3>

				<div className="emailwrapper" >
					<a href={"mailto:"+props.name.split(" ")[0]+"@dynamo.com?subject=Dynamo Member Contact&body=Please add your question or comment here."} className="contactbtn btn" ><i className="fa fa-envelope"></i> Contact</a>
				</div>
			</div>
		</div>
	</>)
}

export default Team;