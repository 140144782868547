import React, { useState, useEffect, useReducer } from "react";

import "../css/Common.css";
import "../css/shop.css";
import "../css/carousel.css";
import PageHeader from "./Util/PageHeader";
import GradButton from "./Util/GradButton";
import "../css/shop.css";
import { useParams } from "react-router-dom";

import { getCar } from "./Cars.js";

import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel from "./Carousel.js";

import { Pannellum, PannellumVideo } from "pannellum-react";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import CarCard from "./CarCard";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import $ from "jquery";

const CarDetail = (props) => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(    {
	"title": "",
	"price": 0,
	"mileage": 0,
	"battery": 0,
	"id": 0,
	"urlslug": "",
	"year": 0,
	"make": "",
	"model": "",
	"body": "",
	"imagesInterior":[],
	"imagesExterior":[],
	"imagePan": "",
	"imageMain": "",
	"keys": 0,
	"drivetype": "",
	"colorExterior": "",
	"colorInterior": "",
	"features": {
		"Comfort": [],
		"Entertainment": [],
		"Tech": [],
		"Interior": [],
		"Exterior": [],
		"Mechanical": [],
	},
	"History": {
		"Age": 0,
		"Country": "",
		"License": "",
		"Ownership": 0,
		"Usage": "",
		"Accidents": {
			"Front": "",
			"Rear": "",
			"Left": "",
			"Right": "",
			"Roof": "",
			"FrontWindshield": "",
			"RearWindshield": "",
			"Interior": "",
			"Engine": "",
			"Heat": "",
			"Water": "",
			"Mechanical": "",
			"Other": "",
			"Cosmetic": ""
		},
		"comments": ""
	}
	
});

	const [pD, setPD] = useState({ //parsed data
		"featurespecs": {},
		"items": [],
		"items2": [],
		"fets": [],
		"overviewSpecs": {},
		"damage": {}
	}); 
  const setting = {
    dragSpeed: 1.25,
    itemWidth: 300,
    itemHeight: 250,
    itemSideOffsets: 5,
  };

  const itemStyle = {
    width: `${setting.itemWidth}px`,
    height: `${setting.itemHeight}px`,
    margin: `0px ${setting.itemSideOffsets}px`,
  };



  const [calc, changeCalc] = useState(0);
  const [monthsE, setMonthsE] = useState(24);

  useEffect(() => {
    getLoan();
  }, [monthsE]);

  useEffect(() => {
	getData()
  }, [])

  useEffect(() => {
	parseData()
	getLoan()
  }, [data])

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const getLoan = () => {
    let down = $("#down").val();
    let trade = $("#trade").val();
    let credit = $("#credit").val();
    let months = monthsE;
    loan = loan * 1.0825; // tax;
    loan = loan * 1.05; // title & fees
    var loan = data.price - down - trade;
    var interest =
      (((350 - Math.max(1, credit - 450)) / 350) * 22 + 4.5) / 100 / 12;
    var monthly =
      (loan * (interest * Math.pow(1 + interest, months))) /
      (Math.pow(1 + interest, months) - 1);
    changeCalc(monthly.toFixed(2));
  };

  let featurespecs = {};
  let items = [];
  let items2 = [];
  let fets = [];
	
  let overviewSpecs = {}
  let damage = {}

  const parseData = () => {

	for (var i = 0; i < data.imagesExterior.length; ++i) {
	  items.push(
		<div>
		  <img src={data.imagesExterior[i]} />
		</div>
	  );
	}
	for (var i = 0; i < data.imagesInterior.length; ++i) {
	  items2.push(
		<div>
		  <img src={data.imagesInterior[i]} />
		</div>
	  );
	}
	  
	
	  for (const [key, value] of Object.entries(data.features)) {
		fets.push([]);
		for (const v in value) {
		  fets[fets.length - 1].push(
			<div className="col-md-6 mtop20">
			  <div className="text text125">{value[v]}</div>
			</div>
		  );
		}
	  }
	
    overviewSpecs = {
      DynamoID: data.id,
      Miles: data.mileage,
      Make: data.make,
      Model: data.model,
      Year: data.year,
      Price: "$" + data.price,
      "Retained Battery Performance": data.battery + "%",
      "Interior Color": data.colorInterior,
      "Exterior Color": data.colorExterior,
      Transmission: data.drivetype,
      Keys: data.keys,
    };

    damage = {
      "Front Bumper": data.History.Accidents.Front,
      "Rear Bumper": data.History.Accidents.Rear,
      "Left Side": data.History.Accidents.Left,
      "Right Side": data.History.Accidents.Right,
      Roof: data.History.Accidents.Roof,
      "Front Windshield": data.History.Accidents.FrontWindshield,
      "Rear Windshield": data.History.Accidents.RearWindshield,
      Interior: data.History.Accidents.Interior,
      Engine: data.History.Accidents.Engine,
      Heat: data.History.Accidents.Heat,
      Water: data.History.Accidents.Water,
      Mechanical: data.History.Accidents.Mechanical,
      Other: data.History.Accidents.Other,
    };

	setLoading(false)
	
	let PDClone = JSON.parse(JSON.stringify([pD]))
	pD.featurespecs = featurespecs
	pD.overviewSpecs = overviewSpecs
	pD.damage = damage
	pD.items = items
	pD.items2 = items2
	pD.fets = fets
	setPD(pD)
	

  };
  const getData = () => {
	$.ajax({
		url: "https://dynamo.pulkith.com/server/carAPI.php",
		data: {
		  auth: "",
		  request: "get_specific_car",
		  id: id,
		},
		type: "post",
		async: true,
		success: function (output) {
		  console.log(JSON.parse(output));
		  setData(JSON.parse(output))
		},
	  });
  }

  


  return (
    <div>
		{loading && 
		            <div
					style={{
					  display: "flex",
					  alignItems: "center",
					  justifyContent: "center",
					  flexDirection: "column",
					}}
				  >
					<Spinner animation="border" role="status">
					  <span className="visually-hidden">Loading...</span>
					</Spinner>
				  </div>
		}

		{!loading &&
		<div className="cardetail">
		<div className="regwrapper">
		  <div className="sec2">
			<div className="row">
			  <div className="text shMed" style={{marginLeft: "10px"}}> {data.title}</div>
			</div>
			<div className="row mtop10 d-none d-md-block">
			  <div className="col-md-10">
				<div className="row">
				  <div className="col-md-auto text text2 boldtext">
					${data.price}{" "}
				  </div>
				  <div className="col-md-auto text text2 divgray"> |</div>
				  <div className="col-md-auto text text2 boldtext">
					{data.mileage} miles{" "}
				  </div>
				  <div className="col-md-auto text text2 divgray"> |</div>
				  <div className="col-md-auto text text2 boldtext ">
					{data.battery} %
				  </div>
				</div>
			  </div>
			  {/* <div className="col-md-1 text shSmall">
				<i className="fa fa-calculator"></i>
			  </div>
			  <div className="col-md-1 text shSmall">
				<div className="row">
				  <i className="fa fa-heart"></i>
				</div>
			  </div> */}
			</div>
			<div className="row"></div>
		  </div>
		</div>
		<div>
		  <div className="row">
			<div className="col-md-6">
			  <Pannellum
				width="100%"
				height="500px"
				image={data.imagePan}
				style={{ marginTop: "0px" }}
				pitch={10}
				yaw={180}
				hfov={110}
				autoLoad
				showZoomCtrl={false}
				onLoad={() => {
				  console.log("panorama loaded");
				}}
			  />
			  <div className="text tex125">
				Drag to view the inside in 360 degrees.
			  </div>
			</div>
			<div className="col-md-6">
			  <div className="col-md-12">
				{/* <div className="text shSmall bold">Exterior</div> */}
				<Carousel _data={pD.items} {...setting}>
				  {data.imagesExterior.map((i, _i) => (
					<div key={_i} className="maxsizeimg" style={{ ...itemStyle }}>
						<div>
							<img src={i} />
						</div>
					</div>
				  ))}
				</Carousel>
			  </div>
			  <div className="col-md-12 " style={{ marginTop: "10px" }}>
				{/* <div className="text shSmall bold">Interior</div> */}
				<Carousel _data={pD.items2} {...setting}>
				  {data.imagesInterior.map((i, _i) => (
					<div key={_i} className="maxsizeimg" style={{ ...itemStyle }}>
					  	<div>
							<img src={i} />
						</div>
					</div>
				  ))}
				</Carousel>
			  </div>
			</div>
		  </div>
		</div>
  
		<div className="regwrapper shop">
		  <div className="sec2">
			<Tab.Container id="left-tabs-example" defaultActiveKey="first">
			  <Row>
				<Col md={3}>
				  <Nav variant="pills" className="flex-column">
					<Nav.Item>
					  <Nav.Link eventKey="first">Overview</Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="second">Features & Specs</Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="third">History & Inspection</Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="four">Comments & Warranty </Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="five">Financing </Nav.Link>
					</Nav.Item>
				  </Nav>
				</Col>
				<Col md={9} className="mtop10">
				  <Tab.Content>
					<Tab.Pane eventKey="first">
					  <div className="row">
						<div className="text shSmall">Overview</div>
					  </div>
					  <div className="divider"></div>
					  <div className="row mtop20">
						{
							Object.keys(pD.overviewSpecs).map(key => {
								return(
								<div className="col-md-4 topmargin">
									<div className="text text2 boldtext">{pD.overviewSpecs[key]}</div>
									<div className="text">{key}</div>
								  </div>
								)
							})
						}
					  </div>
  
					  {/* <GradButton text="Download Detail PDF" /> */}
					</Tab.Pane>
					<Tab.Pane eventKey="second">
					  <div className="row">
						<div className="text shSmall">Features & Specs</div>
					  </div>
					  <div className="divider"></div>
					  <div className="row mtop20">
						<div className="text text175 boldtext">Comfort</div>
					  </div>
					  <div className="row">{pD.fets[0]}</div>
					  <div className="row mtop75">
						<div className="text text175 boldtext">Entertainment</div>
					  </div>
					  <div className="row">{pD.fets[1]}</div>
					  <div className="row mtop75">
						<div className="text text175 boldtext">Technology</div>
					  </div>
					  <div className="row">{pD.fets[2]}</div>
					  <div className="row mtop75">
						<div className="text text175 boldtext">Interior</div>
					  </div>
					  <div className="row">{pD.fets[3]}</div>
					  <div className="row mtop75">
						<div className="text text175 boldtext">Exterior</div>
					  </div>
					  <div className="row">{pD.fets[4]}</div>
					  <div className="row mtop75">
						<div className="text text175 boldtext">Mechanical</div>
					  </div>
					  <div className="row">{pD.fets[5]}</div>
					</Tab.Pane>
					<Tab.Pane eventKey="third">
					  <div className="row">
						<div className="text shSmall">History & Inspection</div>
					  </div>
					  <div className="divider"></div>
					  <div className="row mtop20">
						<div className="text text2">
						  <i className="fa fa-check check"></i>This car has a
						  great history
						</div>
						<div className="row mtop75">
						  <div className="col-md-4 topmargin">
							<div className="text text2 boldtext">
							  {data.History.Age}
							</div>
							<div className="text">Age</div>
						  </div>
						  <div className="col-md-4 topmargin">
							<div className="text text2 boldtext">
							  {data.History.Country}
							</div>
							<div className="text">Country</div>
						  </div>
						  <div className="col-md-4 topmargin">
							<div className="text text2 boldtext">
							  {data.History.License}
							</div>
							<div className="text">Current License</div>
						  </div>
						  <div className="col-md-8 topmargin">
							<div className="text text2 boldtext">
							  {data.History.Usage}
							</div>
							<div className="text">Usage & Damage</div>
						  </div>
						  <div className="col-md-4 topmargin">
							<div className="text text2 boldtext">
							  {data.History.Ownership}
							</div>
							<div className="text">Number of Past Owners</div>
						  </div>
						</div>
					  </div>
  
					  <div className="divider"></div>
					  <div className="text text2 boldtext mtop10">
						Extensive Inspection Report
					  </div>
					  <div className="text text mtop10">
						Full Damage, Extension, and History Report available at
						Dynamo Lot.
					  </div>
					  <div
						className="row mtop10 damageDiv"
						style={{ backgroundColor: "white" }}
					  >
						
						{Object.keys(pD.damage).map(key => {
							return (
								<div className="col-md-12 mtop10">
								<div className="row">
								  <div className="col-md-1">
									<div className="text text125 ">
									  <i className="fa fa-check check"></i>
									</div>
								  </div>
								  <div className="col-md-11">
									<div className="text text125" style={{ color: "black" }}>
									  {key} Damage: {pD.damage[key]}
									</div>
								  </div>
								</div>
								<div className="damageDivDivider"></div>
							  </div>
							)
						})}
					  </div>
					</Tab.Pane>
					<Tab.Pane eventKey="four">
					  <div className="row">
						<div className="text shSmall">Seller Comments</div>
					  </div>
					  <div className="divider"></div>
					  <div className="row mtop10">
						<div className="text text2">{data.History.comments}</div>
					  </div>
					  <div className="row mtop75">
						<div className="text shSmall">Warranty</div>
					  </div>
					  <div className="divider"></div>
					  <div className="row mtop10">
						<div className="text text">
						  This car qualifies for the Dynamo Warranty. Please
						  contact us for more details.
						  <div className="returnimage mtop20">
							<img
							  className="returnimage"
							  src={require("../assets/return.png")}
							></img>
						  </div>
						</div>
					  </div>
					</Tab.Pane>
					<Tab.Pane eventKey="five">
					  <div className="row">
						<div className="text shSmall">Financing</div>
					  </div>
					  <div className="divider"></div>
  
					  <div className="text text2 mtop10">
						We offer financing through multiple options. Use the
						calculator below for a quick estimate of your monthly
						payments.
					  </div>
					  <div className="row mtop10 shop ">
						<div className="calcwrapper">
						  <div
							className="text shSmall boldtext"
							style={{ color: "black" }}
						  >
							Financing Calculator
						  </div>
						  <div className="mtop10"></div>
						  <div className="mtop10"></div>
						  <FloatingLabel
							controlId="down"
							label="Down Payment"
							onChange={() => getLoan()}
						  >
							<Form.Control type="text" placeholder="36000" />
						  </FloatingLabel>
						  <div className="mtop10"></div>
						  <FloatingLabel
							controlId="trade"
							label="Trade in Value (Optional)"
							onChange={() => getLoan()}
						  >
							<Form.Control type="text" placeholder="12000" />
						  </FloatingLabel>
						  <div className="mtop10"></div>
						  <FloatingLabel
							controlId="credit"
							label="Credit Score (FICO)"
							onChange={() => getLoan()}
						  >
							<Form.Control type="text" placeholder="720" />
						  </FloatingLabel>
						  <FloatingLabel
							controlId="floatingSelectGrid"
							label="Term Length"
						  >
							<div className="mtop10"></div>
							<Form.Select
							  aria-label="Floating label select example"
							  controlId="months"
							  onChange={(e) => {
								setMonthsE(e.target.value);
							  }}
							>
							  <option value="24">24 Months</option>
							  <option value="36">36 Months</option>
							  <option value="48">48 Months</option>
							  <option value="60">60 Months</option>
							  <option value="72">72 Months</option>
							</Form.Select>
						  </FloatingLabel>
						  <div className="mtop20"></div>
						  <div className="divider"></div>
						  <div className="mtop10"></div>
						  <div className="mtop20"></div>
						  <div className="solwrapper">
							<div className="text shSmall boldtext text-center">
							  ${calc}/mo
							</div>
							<div className="text text boldtext text-center mtop10">
							  Estimated Monthly Payment
							</div>
						  </div>
						</div>
					  </div>
					</Tab.Pane>
				  </Tab.Content>
				</Col>
			  </Row>
			</Tab.Container>
		  </div>
		</div>
		<div className="shop sec2">
		  <div className="regwrapper">
			<div className="text shSmall mtop20">Similar Cars</div>
			<div className="damageDivDivider"></div>
		  </div>
		  <div className="row mtop20">
			<div
			  className="col-md-12"
			  style={{
				marginLeft: "0px",
				paddingLeft: "0px",
				marginRight: "0px",
				paddingRight: "0px",
			  }}
			>
			  <div
				className="row"
				style={{
				  marginLeft: "0px",
				  paddingLeft: "0px",
				  marginRight: "0px",
				  paddingRight: "0px",
				}}
			  >
				<CarCard size="col-12 col-sm-6 col-lg-4 col-xxl-3" />
				<CarCard size="col-12 col-sm-6 col-lg-4 col-xxl-3" />
				<CarCard size="col-12 col-sm-6 col-lg-4 col-xxl-3" />
				<CarCard size="col-12 col-sm-6 col-lg-4 col-xxl-3" />
			  </div>
			</div>
		  </div>
		</div>
	  </div>
		}
	</div>
  );
};

export default CarDetail;
