
import React, { useState } from 'react';

export default function NotFound() {
  return (
    <div className="sec1">
      <div className="regwrapper">
        <div className="headingLarge">404 Page Not Found</div>
      </div>
      </div>
  );
}