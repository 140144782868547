import React, { useState } from 'react';

import '../../css/Landing.css'

export default function VisitBtn(props){

	let target = (props.ex) ? "_blank" : "_self";
	let size = (props.size) ? props.size : "1rem"
	return (
		<div className="visitLink">
			<a href={props.link} className="link" target={target}>
				<span className="text" style={{fontSize: size}}>
					{props.text}
				</span>
			</a>
		</div>
	)
}