
import React, { useState } from 'react';

import '../../css/Common.css';
import GradButton from './../Util/GradButton';
import PageHeader from '../Util/PageHeader';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Join = (props) => {

	const questions = [
		"What is the compensation for my work?",
		"Who are you looking for?",
		"What are the expectations and commitments?",
		"What can you offer over other organizations?",
		"How can I get a leadership position?",
		"How can I learn more/Who can I contact for questions?",
		"What is the proccess?",

	];
	const answers = [
		"We offer between $15-$40 per hour for most positions. You can get more info by contacting our office. ",
		"Browse our openings, and apply if you are interested in the position. We are looking for individuals who are passionate about Electric Cars, and are willing to learn and grow with us.",
		"Expectations and Commitments are different for each position. Please read the job description for each position to see what is expected of you. Each position varies from around 20-40 hours per week.",
		"Dynamo is a growing organization, and we are able to offer a lot of flexibility and freedom to our members. We are also able to offer a lot of experience and knowledge in the field, and are able to offer a lot of opportunities for growth.",
		"Leadership positions are available for those who should success in their role. We typically promote members to leadership positions, as opposed to hiring from outside.",
		"You can contact us through the channels on the contact page.",
		"After you apply, your resume will be read. If you are chosen for the next round, you will be interviewed by an exectutive, and the leader of the team you will prospectively be in."
		
	];

	let FAQS = [];

	for (var i = 0; i < questions.length; ++i) {
		FAQS.push(
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Q: {questions[i]}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>A: {answers[i]}</Typography>
				</AccordionDetails>
			</Accordion>
		);
	}

	let openpositions = [
		{
			title: "Mobile Developer",
			desc: "Deploy the Machine Learning Pipeline to the mobile application.",
			team: "Model and Application Development",
			skills: ["Swift", "React Native", "Java"],
		},
		{
			title: "Opportunity & Outreach Lead",
			desc: "Reach out to car dealerships to gain partnerships and opportunities for Dynamo.",
			team: "Enterprise Operations",
			skills: ["Social Media", "Networking", "Commmunication"],
		},
		{
			title: "Recruitment Lead",
			desc: "Recruit new individuals to Dynamo.",
			team: "Enterprise Operations",
			skills: ["Social Media", "Networking", "Commmunication"],
		},
		{
			title: "Machine Learning Engineer",
			desc: "Use various machine learning algorithms to analyze data and implement new algorithms to improve the accuracy of our applications. Coordinate with the Research team to implement new findings.",
			team: "Model and Application Development",
			skills: ["Machine Learning", "Python", "Sci-Kit Learn", "TenserFlow + Keras"],
		},
		{
			title: "Sales Representatives",
			desc: "Work with customers to sell cars and vehicles.",
			team: "Reseach",
			skills: ["Research", "Paper-Writing", "Communication"],
		},
		{
			title: "Social Media Outreach Lead",
			desc: "Create publicity for Dynamo, through social media platforms. Create content that is relevant to the community, and look for opportunties.",
			team: "Enterprise Operations",
			skills: ["Design", "Social Media", "Networking"],
		},
		{
			title: "Graphic	Designer",
			desc: "Create and design graphics for the website, applications, and publications.",
			team: "Model and Application Development",
			skills: ["Graphic Design", "UI/UX"],
		},
	]

	let openpositionslist = [];

	openpositionslist.push(<div className="seperator"></div>)
	for(var i = 0; i < openpositions.length; ++i) {
		openpositionslist.push(<Pub data={openpositions[i]}></Pub>)
		openpositionslist.push(<div className="seperator"></div>)
	}


  return (
	
    <div className="njas">
		
		<div className="sec1">
			<div className="regwrapper">
				<h2 className="headinggrad">Browse our open Positions</h2>
				<h2 className="text shMed">Open Positions</h2>
				<br />
				
				{openpositionslist}

			</div>	
		</div>
		<div className="sec2">
			<div className="regwrapper">
				<h2 className="headinggrad">Common Questions</h2>
				<h2 className="text shSmall">FAQS</h2>
				<br />
				
				{FAQS}

			</div>	
		</div>
    </div>
  );
}

export default Join ;

const Pub = (props) => { 

	let skills = [];
	for(var i = 0; i < props.data.skills.length; ++i) {
		skills.push(<div className="paraReg tag">{props.data.skills[i]}</div>)
	}

	return(
		<div className="pub">

			<div className="row">

				<div className="col-12 col-md-10">
					<h2 className="text shSmall">{props.data.title}</h2>
					<div className='paraReg thintext tag color2'>{props.data.team} </div>
					<p className="text text125" style={{marginTop: "15px"}}> Job Description: <br />{props.data.desc}

					</p>
					<br />
					

					<p className="text text125">
						Skills: {skills}
					</p>
				</div>

				<div className="col-12 col-md-2 d-flex justify-content-center">
					<div>
						<GradButton text="Apply Now" link={`mailto:paruchuri@pulkith.com?subject=Dynamo Application - ${props.data.title}&body=Please attach your resume, and a short description of why you believe you would be a great fit.`} />
					</div>
				</div>
			</div>
			
		</div>
	)
}