import React, { useState, useEffect } from "react";

import "../css/Common.css";
import "../css/Account.css";
import "../css/Sell.css";
import PageHeader from "./Util/PageHeader";
import GradButton from "./Util/GradButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import $ from "jquery";
import bcrypt from "bcryptjs";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

class SellModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: "",
			loadingMsg: "Validating VIN",
      response: {}
		};
	}

  VINData(vin, model, make, year) {
    $.ajax({ url: 'https://dynamo.pulkith.com/server/VIN.php',
      data: {
        "auth": "",
        "request": "value",
        "vin": vin,
        "model": model,
        "make": make,
        "year": year,
      },
      type: 'post',
      async: true,
      success: (output) => {
        let response = JSON.parse(output);
        console.log(response)
        this.setState({loading: false, response: response})
      }
    });
  }
    validateResponse(vin, response) {
			if(response.Results[0].ErrorText.charAt(0) !== "0") {
				this.setState({loading: false, error: "The VIN provided is invalid. Please verify the VIN and try again."})
			} else {
				let make = response.Results[0].Make
				let model = response.Results[0].Model
				let year = response.Results[0].ModelYear

				this.setState({loadingMsg: "Retrieving Information"})

        this.VINData(vin, model, make, year)
			}
    }
    VINLookup(vin) {
      

      $.ajax({
        url: "https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvaluesextended/" + vin + "?format=json",
        data: {},
        type: "get",
        async: true,
        success:  (output) => {
          this.validateResponse(vin, output)
        }
      });
    };

    VINValidation(vin) {
      let str = vin.toUpperCase().replace(/\s/g, ""); //remove spaces
	    let match = str.match(/^[0-9A-Z]+$/)
      return (str.length === 17) && (match && match.index == 0 && match.length == 1); //check alphanumeric
    };


    runAnalysis(vin) {
      this.setState({response: {}})
		let valid = false;
		if (vin && vin.length > 0) {
			valid = this.VINValidation(vin);
		}
		if (!valid) {
			this.setState({loading: false, error: "The VIN provided has invalid characters or is the incorrect length. Please verify the VIN and try again."})

		} else {
			this.setState({loadingMsg: "Verifying VIN"})
			this.VINLookup(vin)
		}
    };

	componentDidMount() {
		this.runAnalysis(this.props.vin)
	}
  //   //WP0CA29919S740343

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Car Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: "5px" }}>{this.state.loadingMsg}</div>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}

			{this.state.error.length > 0 && 
				<div className="text175 mtop10">{this.state.error}</div>
			}

      { this.state.response.response &&

      <div>
        <div className="text text125 text-center" style={{color: "black"}}>{this.state.response.response}<br /><br /></div>

        <div className="text text125 text-center" style={{color: "black"}}>We have found this VIN corresponds to a {this.state.response.year} {this.state.response.make} {this.state.response.model}<br />
        Your quote is below. You will need to bring your vehicle in to the Dynamo Lot for an official estimate and damage report.
        <br /><br /><br /></div>

        <div className="text text2 text-center" style={{color: "black"}}>${this.state.response.light}</div>
        <div className="text text125 text-center" style={{color: "black"}}>Light Damage<br /><br /></div>

        <div className="text text2 text-center" style={{color: "black"}}>${this.state.response.medium}</div>
        <div className="text text125 text-center" style={{color: "black"}}>Medium Damage<br /><br /></div>

        <div className="text text2 text-center" style={{color: "black"}}>${this.state.response.heavy}</div>
        <div className="text text125 text-center" style={{color: "black"}}>Heavy Damage<br /></div>


      </div>

      }

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const Sell = (props) => {
  const [modalShow, setModalShow] = React.useState(false);




  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let VIN = params.get("VIN");
  
      if (VIN) {
        $("#VINID").val(VIN);
        VIN = null
        setModalShow(true);
        
      }

  }, []);

  return (
    <div className="Sell">
      {modalShow && (
        <SellModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          vin={$("#VINID").val()}
        />
      )}
      <div
        className="backimg background-tint"
        style={{ width: "100vw" }}
      >
        <div className="centerwrapper">
          <div className="loginwrapper">
            <div className="text shSmall text-center init">
              Sell or Trade your car
            </div>
            <div className="text text125 greytext text-center init">
              Enter your car details below to get an estimate in just a few
              minutes. You will have to bring your car to the Dynamo Lot for an
              official appraisal.
            </div>
            <div className="mtop20"></div>
            <div className="divider"></div>
            <div className="mtop20"></div>
            <FloatingLabel controlId="VINID" label="VIN" className="mb-3">
              <Form.Control as="textarea" placeholder="Leave a comment here" />
            </FloatingLabel>
            <Button
              variant="primary"
              size="lg"
              block
              style={{ width: "100%" }}
              onClick={() => setModalShow(true)}
            >
              Get Started
            </Button>
            <div className="mtop75"></div>
            <div className="divider"></div>
            <div className="mtop20"></div>
            <div className="text grey">
              Whats a VIN? A vehicle identification number (VIN) is a
              17-character unique code to identify individual motor vehicles. It
              can be used to retrieve car details and track vehicle history,
              service history, and more.
            </div>
            <div className="text grey mtop20">
              How do I find my VIN? You can find your VIN on your vehicle
              registration, insurance card, or on the driver's side dashboard.
              Learn more{" "}
              <a
                href="https://www.txdmv.gov/motorists/how-to-find-the-vin"
                target="_blank"
              >
                here &#x2197;.
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sec1">
        <div className="sec1inneractionwrapper">
          <div className="text shSmall text-center">Book an appointment</div>
          <div className="text text2 text-center mtop75">
            Book an appointment today to sell or trade your car at the Dynamo
            lot hassle-free.
          </div>
          <div className="centerButton mtop75">
            <GradButton
              text="Book an appointment"
              className="mtop10"
              link="/dashboard"
            />
          </div>
        </div>
      </div>
      <div className="sec2">
        <div className="regwrapper">
          <img
            src={require("../assets/howworks.png")}
            style={{ width: "100%" }}
          ></img>
        </div>
      </div>
      <div className="sec1">
        <div className="regwrapper">
          <img
            src={require("../assets/payment.png")}
            style={{ width: "100%" }}
          ></img>
        </div>
      </div>
      <div className="sec2">
        <div className="sec2inneractionwrapper">
          <div className="text shSmall text-center init">
            Still have questions? Ask your questions!
          </div>

          <div className="centerButton mtop20">
            <GradButton text="Contact Us" className="mtop10" link="/Contact" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sell;
