
import React, { useState } from 'react';

import '../../css/Common.css';
import PageHeader from './../Util/PageHeader';
import GradButton from './../Util/GradButton';

const About = (props) => {
  return (
	
    <div className="aboutpage">
		
		<div className="sec1">
			<div className='regwrapper'>
				<h2 className="headinggrad">Our Mission</h2>
				<h2 className="text shSmall">Dynamo aims to make owning an electric car more affordable and accessible</h2>
				<p className="text text15 mtop20">
					Dynamo facilitates the purchase of electric cars by providing a platform for customers to buy and sell used electric cars. We aim to provide helpful customer service, and make the process as simple as possible.  We are a one-stop shop for all things electric cars. 
				</p>
			</div>
		</div>
		<div className="sec2">
			<div className='regwrapper'>
				<h2 className="headingSmall gradtext">By the Numbers</h2>
				<div className="row gradborder sec1 gy-3" style={{textAlign: "center", marginTop: "50px"}}>
					<div className="col-md-4">
						<h3 className="text shSmall">374</h3>
						<h3 className="text text15">Cars sold</h3>
					</div>
					<div className="col-md-4">
						<h3 className="text shSmall">108</h3>
						<h3 className="text text15">Cars bought</h3>
					</div>
					<div className="col-md-4">
						<h3 className="text shSmall">$15M+</h3>
						<h3 className="text text15">Provided Estimated Savings</h3>
					</div>
					<div className="col-md-4">
						<h3 className="text shSmall">98%</h3>
						<h3 className="text text15">Retention Rate</h3>
					</div>
					<div className="col-md-4">
						<h3 className="text shSmall">18</h3>
						<h3 className="text text15">Awards</h3>
					</div>
					<div className="col-md-4">
						<h3 className="text shSmall">92%</h3>
						<h3 className="text text15">Cars Bought</h3>
					</div>
				</div>
			</div>
		</div>

		<div className="sec1">
			<div className="sec1inneractionwrapper">
				<h2 className="headinggrad">Dynamo is growing</h2>
				<h2 className="text shSmall">Grow with us</h2>
				<br />
				<p className="text text2">
					Join our team and help us sell Electric Cars.
					<br /><br />
					We have open positions in Leadership, Development, Machine Learning, Graphic Design, Enterprise Operations, and more!	
				</p>
				<br />
				<GradButton text="Let's Work Together" link="/Join"/>
			</div>
		</div>

		<div className="sec2">
			<div className="regwrapper">
				<div className="text shSmall">Our Story</div>
				<p className="text text175">
					<br />
					Dynamo was founded in 2012 by a group of students from Dallas. Dynamo was created to address the need for a switch to electric cars, and barriers to conversion like cost and availability.
					<br /><br />
					Today Dynamo is one of the world's top-rated electric car resell websites, and we are just getting started!
					<br /><br />
					<GradButton link="/News" text="Read our Blog" />
				</p>
			</div>
		</div>
		<div className="sec1">
			<div className="regwrapper">
				<div className="row">
					<div className="col-12 col-md-6 leftrightmargin padright" >
						<div className="text shSmall">Buy a Car</div>
						<div className="text text15">
							<br />
							Dynamo provides some of the cheapest and highest quality electric cars on the resell market!
							<br /><br />

						</div>
						<GradButton link="/Projects" text="Buy a Car Today" />
					</div>
					<div className="col-12 col-md-6 d-none d-md-block" style={{marginBottom: "100px"}}>
						<div className="maxsizeimgwrapper">
							<img className="" src="https://images.cdn.circlesix.co/image/1/700/0/uploads/posts/2016/09/2addca8d9288d9468471f8d954797857.jpg" alt="research" style={{height: "100%", width: "100%", objectFit: "contain"}}/>
						</div>
					</div>
					<div className="col-12 col-md-6 d-none d-md-block" style={{marginBottom: "100px"}}>


					<img className="" src="https://cdn.dribbble.com/users/1626229/screenshots/12570549/media/7da0e33ba3a8c299d38e22ad07ab7f73.jpg?compress=1&resize=400x300" alt="leadership" style={{height: "100%", width: "100%", objectFit: "contain"}}/>
					</div>
					<div className={"col-12 col-md-6 leftrightmargin padleft"}>
						<div className="headinggrad">Experienced</div>
						<div className="text shSmall">Leadership</div>
						<div className="text text15">
							<br />
							Founded and led by some of the nation's most experienced leaders, Dynamo 
							has a proven team of members who are passionate and commited to the our mission.
							<br /><br />
							
						</div>
						<GradButton link="/Team" text="View our Team" />
					</div>
					<div className="" style={{marginBottom: "100px"}}></div>



					<div className="col-12 col-md-6 padright">
						<div className="headinggrad">Internationally Recognized</div>
						<div className="text shSmall">Awards and Partners</div>
						<div className="text text15">
								<br />
								Dynamo is backed by some of the world's most recognized corporations, that are leading the charge on electric cars.
								<br /><br />
								
								Dynamo has also been recognized internationally for its high quality.

								<br /><br />
								
							</div>
							<GradButton link="/Partners" text="View our Partners" />
							<GradButton link="/Recognition" text="View our Recognition" />
					</div>
					<div className="col-6" style={{marginBottom: "100px"}}>
					<img className="col-12 col-md-6 d-none d-md-block" src="https://www.multivu.com/players/English/53276-kbb-best-resale-value-awards-2012/flexSwf/impAsset/image/1ccafc0b-c2f7-4d08-88b1-8067774c130b.jpg" alt="partners" style={{height: "100%", width: "100%", objectFit: "contain"}}/>
					</div>
				</div>
			</div>
		</div>


    </div>
  );
}


export default About ;