
import React, { useState, useEffect } from 'react';
import $ from "jquery"
import '../css/Common.css';
import PageHeader from './Util/PageHeader';
import GradButton from './Util/GradButton';
import CarCard from './CarCard'
import '../css/shop.css'
import Spinner from "react-bootstrap/Spinner";

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Shop = (props) => {
	const [cars, setCars] = useState([])
	const [allCars, setAllCars] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedFilters, setSelectedFilters] = useState([])
	const [filters, setFilters] = useState({
		"make": [],
		"price": [],
		"mileage": [],
		"features": [],
		"interior": [],
		"exterior": [],
		"body": [],
		"battery": []
	})


	const toggleFilter = (filter, parent, add) => {
		let contain = filters[parent].includes(filter)


		//make copies of filters to propogate changes
		let copySF = JSON.parse(JSON.stringify(selectedFilters))
		let copyF = JSON.parse(JSON.stringify(filters))


		if(add && !contain) {
			copySF.push(filter)
			setSelectedFilters(copySF)
			

			copyF[parent].push(filter)
			setFilters(copyF)
		}
		if(!add && contain) {
			copySF = copySF.filter(item => item !== filter)
			setSelectedFilters(copySF)

			copyF[parent]= copyF[parent].filter(item => item !== filter)
			setFilters(copyF)
			
		}
	}

	const searchCars = () => { //can be made more effecient with trie but good enough for purposes here becuase title names are short
		const terms = $("#searchbar").val().toLowerCase().trim().split(" ") //search terms


		for(var i = 0; i < terms.length; i++)
			terms[i] = terms[i].replace(/\W/g, '') //remove non alphanumeric characters

		var titles = []
		var finalCars = [] //displayed Cars

		for(var i = 0; i < allCars.length; i++) {
			titles.push(allCars[i].title.replace(/\W/g, '').toLowerCase()) //remove alphanumeric characters from car titles
		}

	
		for(var i = 0; i < allCars.length; i++) {
			var curdex = 0;

			if(terms.length == 1 && terms[0] == "") { //if no search term
				finalCars.push(allCars[i]) //display car
				continue;
			}
			var works = true 
			for(var k = 0; k < terms.length; ++k) { //for each search term
				var curdex = 0
				if(terms.length == 0) continue; //if search term is a split space, skip

				for(var j = 0; j < titles[i].length; ++j) { //for each character in the car title
					if(titles[i][j] == terms[k][curdex]) curdex++; //if the character matches the next search term character, proceed
					if(curdex == terms[k].length) break; // if all search term characters are found, break
				}
				if(curdex != terms[k].length) works =  false; //if not all search terms are in the car name
				
			}
			if(works) finalCars.push(allCars[i]) //if all search terms are in the car title
		}
		setCars(finalCars) //push cars to be displayed
		setLoading(false)
		
	}

	const fetchCars = () => {

		const POSTfilters = JSON.parse(JSON.stringify(filters))
		
		for(var key in POSTfilters) {
			for(var i = 0; i < POSTfilters[key].length; ++i) {
				POSTfilters[key][i] = POSTfilters[key][i].replace("<", "0-") //server checks between lower and higher range, so we replace less than and more than signs with ranges so the server can parse
				if(POSTfilters[key][i][0] == ">") POSTfilters[key][i] = POSTfilters[key][i].replace(">", "") + "-1000000000"
				POSTfilters[key][i] = POSTfilters[key][i].replace(/K/g, "000")

			}
		}

		setLoading(true)
		$.ajax({ url: 'https://dynamo.pulkith.com/server/carAPI.php',
			data: {
				"auth": "",
				"request":"get_available_cars",
				"make": POSTfilters.make,
				"price": POSTfilters.price,
				"mileage": POSTfilters.mileage,
				"features": POSTfilters.features,
				"interior": POSTfilters.interior,
				"exterior": POSTfilters.exterior,
				"body": POSTfilters.body,
				"battery": POSTfilters.battery
			},
			type: 'post',
			async: true,
			success: function(output) {
				setAllCars(JSON.parse(output))
				searchCars();
			}
		});
	}

	React.useEffect(() => {
		searchCars()
	  }, [allCars]);

	  React.useEffect(() => {
		fetchCars()
	  }, [filters]);


	useEffect(() => {
		fetchCars();
		
	  }, [] )

	  const [show, setShow] = useState(false);

	  const handleClose = () => setShow(false);
	  const handleShow = () => setShow(true);

  return (
	
    <div className="shop">
		<div className="search">
			<Button variant="white" className="hiddenMenuButton d-md-none" onClick={handleShow} >
        		Filters
      		</Button>
		<input type="text" className="searchbar" placeholder="Search by Make, Model, or Keyword" onChange={(e) => {searchCars()}} id="searchbar"></input>
			{/* <i className="fas fa-search"></i> */}
		</div>

		<div className="row" >
			<div className="col-lg-3 col-md-5 col-sm-12 d-none d-md-block" style={{paddingLeft: "0px"}}>
				<div className="filterwrapper">
					<div className="text text175 text-center">Filter</div>

					<div className="row">
						{
							selectedFilters.map((e) => {
								return (
									// &nbsp; <i className="fa fa-times"></i>
									<div className="col-md-auto filtercircle">{e}</div>
								)
							})
						}

					</div>

					<hr></hr>
					<div className="row">
						{/* <span className="text text125"><i className="fa fa-bars"></i>&nbsp; &nbsp; Sort By</span> */}
						
					</div>
					<hr></hr>
					<FilterItem name="Make" options={["Kia","Tesla","Rivian"]} parent="make" callBack={toggleFilter}  selected={selectedFilters} />
					<FilterItem name="Price" options={["<$10K","$10K-$30K","$30K-$50K", "$50K-$75K", "$75K-$100K", ">100K"]} parent="price" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Mileage" options={["<1K mi","1K mi - 10K mi","10K mi - 30K mi", "30K mi - 60K mi", "60K mi - 80K mi", "80K mi - 100K mi", "100K mi - 200K mi", ">200K mi"]} parent="mileage" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Features" options={["Automatic Transmission","Air Conditioning","Surround Sound Speakers", "Roof Rack", "Tinted Windows"]} parent="features" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Interior Color" options={["Black","White","Gray"]} parent="interior" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Exterior Color" options={["Red","Black","White"]} parent="exterior" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Body Type" options={["SUV","Truck","Sedan"]} parent="body" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Battery Health" options={["<30%","30%-50%","50%-60%", "60%-70%", "70%-80%", "80%-90%", "90%-100%"]} parent="battery" callBack={toggleFilter}   selected={selectedFilters} />
					<hr></hr>

					{/* <div className="viewdetailbutton" style={{marginLeft: "10px", marginRight: "10px"}}>Compare Cars</div> */}
				</div>
			</div>

			<Offcanvas show={show} onHide={handleClose} responsive="lg" className="d-block d-md-none" variant="dark" style={{backgroundColor: "black"}} >
				<Offcanvas.Header closeVariant="white" closeButton >
					<Offcanvas.Title className="text shMed">Filters</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<FilterItem name="Make" options={["Kia","Tesla","Rivian"]} parent="make" callBack={toggleFilter} selected={selectedFilters} />
					<FilterItem name="Price" options={["<$10K","$10K-$30K","$30K-$50K", "$50K-$75K", "$75K-$100K", ">100K"]} parent="price" callBack={toggleFilter} selected={selectedFilters} />
					<FilterItem name="Mileage" options={["<1K mi","1K mi - 10K mi","10K mi - 30K mi", "30K mi - 60K mi", "60K mi - 80K mi", "80K mi - 100K mi", "100K mi - 200K mi", ">200K mi"]} parent="mileage" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Features" options={["Automatic Transmission","Air Conditioning","Surround Sound Speakers", "Roof Rack", "Tinted Windows"]} parent="features" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Interior Color" options={["Black","White","Gray"]} parent="interior" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Exterior Color" options={["Red","Black","White"]} parent="exterior" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Body Type" options={["SUV","Truck","Sedan"]} parent="body" callBack={toggleFilter}   selected={selectedFilters} />
					<FilterItem name="Battery Health" options={["<30%","30%-50%","50%-60%", "60%-70%", "70%-80%", "80%-90%", "90%-100%"]} parent="battery" callBack={toggleFilter}   selected={selectedFilters} />
				</Offcanvas.Body>
				</Offcanvas>

			<div className="col-lg-9 col-md-7">
				{ !loading && 
				<div className="row shop" style={{paddingTop: "0px"}}>
				{
					cars.map((car) => {
						return (
							<CarCard data={car} size="col-lg-6 col-xl-4"/>
						)
					})
				}

			</div>
				}

				{loading && 
				            <div
							style={{
							  display: "flex",
							  alignItems: "center",
							  justifyContent: "center",
							  flexDirection: "column",
							  height: "20%"
							}}
						  >
							<Spinner animation="border" role="status">
							  <span className="visually-hidden">Loading...</span>
							</Spinner>
						  </div>
				}

				{(cars.length == 0) && !loading &&
				            <div className="text text2 mtop20" 							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								height: "20%"
							  }}>
							No cars found, please try modifying your search.
						  </div>
				}
			</div> 
			<div className="" style={{marginBottom: "130px"}}>

			</div>
		</div>
    </div>
  );
}

const FilterItem = (props) => { 
	const [open, setOpen] = useState(false);

	let options = [];
	for(let i = 0; i < props.options.length; i++){ 
		options.push(
			<div className="offset-md-1 col-md-11">
				<input type="checkbox" checked={props.selected.includes(props.options[i]) ? true : false}  name={props.options[i]} value={props.options[i]} style={{color: "white"}} onClick={e => props.callBack(props.options[i], props.parent, e.target.checked)}></input> &nbsp; <span className="text text125"> {props.options[i]} </span>
			</div>
		)
	}


	return (
		<div className="shop">
				<div className="filterrow" onClick={() => setOpen(!open)}>
					{!open && <div className="text text15"> {props.name} <i className="fa fa-chevron-right righticon"></i></div>}
					{open && <div className="text text15"> {props.name} <i className="fa fa-chevron-down righticon"></i></div>}
				</div>

				<div className="row" style={{display: (open) ? "block" : "none"}}>
					{options}
				</div>

			<div className="dividerRw"></div>
		</div>
	)
	
}


export default Shop ;


