import React, { useState } from "react";

import "../css/Common.css";
import "../css/Account.css";
import PageHeader from "./Util/PageHeader";
import GradButton from "./Util/GradButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import $ from "jquery";
import bcrypt from "bcryptjs";
import InputGroup from "react-bootstrap/InputGroup";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Login = (props) => {
  const [login, changeLogin] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [validated, setValidated] = useState(false);

  const validateSalt = () => {
    setError("");
    setSuccess("");

    let email = $("#email").val();
    let pass = $("#password").val();
    $.ajax({
      url: "https://dynamo.pulkith.com/server/account.php",
      data: {
        email: email,
        request: "email_to_salt",
      },
      type: "post",
      async: true,
      success: function (output) {
        output = JSON.parse(output);
        if (output.result == "Success") {
          validatePass(output.salt, pass, email);
        } else {
          setError("Invalid Email or Password");
        }
      },
    });
  };

  const validatePass = (salt, pass, email) => {
    let hash = hashpassValidate(salt, pass);
    $.ajax({
      url: "https://dynamo.pulkith.com/server/account.php",
      data: {
        email: email,
        hash: hash,
        request: "password_validation",
      },
      type: "post",
      async: true,
      success: function (output) {
        output = JSON.parse(output);
        if (output.result == "Valid") {
          window.location.href = "/dashboard";
        } else {
          setError("Invalid Email or Password");
        }
      },
    });
  };

  const makeAccount = () => {
    setError("");
    setSuccess("");
    let email = $("#emailSign").val();
    let pass = $("#passSign").val();
    let fName = $("#fNameSign").val();
    let lName = $("#lNameSign").val();

    let hPass = hashPassword(pass);

    $.ajax({
      url: "https://dynamo.pulkith.com/server/account.php",
      data: {
        email: email,
        password: hPass[1],
        salt: hPass[0],
        fName: fName,
        lName: lName,
        request: "sign_up",
      },
      type: "post",
      async: true,
      success: function (output) {
        setSuccess("Account created, please login");
        changeLogin(true);
        // console.log(output);
        // if(output === 'true'){
        // }
        // else if(output === 'Not Logged In'){
        // 	setLogin()
        // }
        // else {}
      },
    });
  };

  const hashPassword = (password) => {
    var salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(password, salt);
    return [salt, hash];
  };

  const hashpassValidate = (salt, password) => {
    var hash = bcrypt.hashSync(password, salt);
    return hash;
  };

  const handleSignUp = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		
		if (form.checkValidity() === true) {
			
		} 

		setValidated(true);
  };

  return (
    <div className="account">
      <div
        className="backimg background-tint"
        style={{ width: "100vw", height: "88vh" }}
      >
        <div className="loginwrapper">
          <div className="imgwrapper">
            <img
              className="logo center"
              src={require("../assets/logo/blocklogo.png")}
              alt="logo"
            />
          </div>

          <div className="text shSmall center-text init">Nice to see you.</div>
          {error.length > 0 && (
            <div className="text text125 text-center mtop10 errortext boldtext">
              {error}
            </div>
          )}
          {success.length > 0 && (
            <div className="text text125 text-center mtop10 successtext boldtext">
              {success}
            </div>
          )}
          {login && (
            <div>
              <FloatingLabel
                controlId="email"
                label="Email address"
                className="mb-3 mtop20"
              >
                <Form.Control type="email" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel controlId="password" label="Password">
                <Form.Control type="password" placeholder="Password" />
              </FloatingLabel>

              <div
                className="loginbtn gradborder"
                onClick={() => validateSalt()}
              >
                <div className="text text2 text-center boldtext">
                  Login &#8594;
                </div>
              </div>

              <div className="text text125 center-text mtop20 text-center">
                <a href="/signup" className="link">
                  Forgot your password?
                </a>
              </div>
              <div className="divider"></div>
              <div className="text text125 center-text mtop20 text-center">
                Don't have an account?{" "}
                <a
                  className="link"
                  onClick={() => {
                    changeLogin(false);
                    return false;
                  }}
                >
                  Sign up
                </a>
              </div>
            </div>
          )}

          {!login && (
            <div>

<Form noValidate validated={validated} onSubmit={handleSignUp}>
						<Form.Group controlId="name" style={{marginBottom: "25px"}}>
							<FloatingLabel controlId="name" label="Full Name" className="mb-3">
								<Form.Control as="textarea" placeholder="Full Name" required />
								<Form.Control.Feedback type="invalid"> Please provide your full name </Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId="email" style={{marginBottom: "25px"}}>
							<FloatingLabel controlId="email" label="Email" className="mb-3">
								<Form.Control type="email" placeholder="Email" required pattern=".+@.+\.com" />
								<Form.Control.Feedback type="invalid"> Please provide a valid email. </Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId="number" style={{marginBottom: "25px"}}>
							<FloatingLabel controlId="number" label="Phone Number" className="mb-3">
								<Form.Control  placeholder="Phone Number" required pattern="[0-9]{10}"/>
								<Form.Control.Feedback type="invalid"> Please provide a valid number. </Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
						<Form.Group controlId="comment" style={{marginBottom: "25px"}}>
							<FloatingLabel controlId="comment" label="Comments" className="mb-3">
								<Form.Control as="textarea" placeholder="Comments" style={{ height: '100px' }} required/>
								<Form.Control.Feedback type="invalid"> Please enter your questions or comments here. </Form.Control.Feedback>
							</FloatingLabel>
						</Form.Group>
						<Button type="submit">Submit form</Button>
					</Form>

              <Form noValidate validated={validated} onSubmit={handleSignUp}>
                <div className="row">
                  <div className="col-6" style={{ padding: "0px", paddingRight: "10px" }} >
                    <Form.Group controlId="sign1">
                    <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>

                      
                    </Form.Group>
                  </div>
                  <div className="col-6"  style={{ padding: "0px", paddingLeft: "10px" }} >
                    <Form.Group>
                      <FloatingLabel  controlId="lNameSign" label="Last Name" className="mb-3 mtop20" >
                        <Form.Control type="text" placeholder="name@example.com" required />
                      </FloatingLabel>
                      <Form.Control.Feedback type="invalid"> Please enter your first name.</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <FloatingLabel controlId="emailSign" label="Email address" className="mb-3">
                    <Form.Control type="email" placeholder="name@example.com" required />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback>
                </Form.Group>


                <Form.Group>
                  <FloatingLabel
                    controlId="passSign" label="Password" className="mb-3" required >
                    <Form.Control type="password" placeholder="Password" />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid"> Please enter your first name. </Form.Control.Feedback>
                </Form.Group>


                <Form.Group>
                  <FloatingLabel controlId="confPassSign" label="Confirm Password" className="mb-3"  required >
                    <Form.Control type="password" placeholder="Password" />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid"> Please enter your first name. </Form.Control.Feedback>
                </Form.Group>



                <Form.Check
                  type={"checkbox"}
                  label={
                    <div>
                      I agree to the
                      <a
                        href={require("../assets/policies/tus.pdf")}
                        without
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href={require("../assets/policies/pp.pdf")}
                        without
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  }
                  id={`1`}
                  style={{ color: "white" }}
                />

                <Form.Check
                  type={"checkbox"}
                  label={`I would like to subscribe to the newsletter`}
                  id={`3`}
                  style={{ color: "white" }}
                />
                <Button type="submit">
                  <div className="loginbtn gradborder">
                    <div className="text text2 text-center boldtext">
                      Sign Up &#8594;
                    </div>
                  </div>
                </Button>
              </Form>
              <div className="divider" style={{ marginTop: "20px" }}></div>
              <div className="text text125 center-text mtop20 text-center">
                Already have an account?{" "}
                <a
                  className="link"
                  onClick={() => {
                    changeLogin(true);
                    return false;
                  }}
                >
                  {" "}
                  Login in
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
