import React, { useState } from 'react';

import '../css/Common.css';
import '../css/shop.css';

const CarCard = (props) => {

	let color = (props.color) ? props.color : "var(--frost-black)";

	let data = (props.data) ? props.data : {
		"make": "Tesla",
		"model": "Model S",
		"year": "2022",
		"price": "50000",
		"image": "https://www.pngall.com/wp-content/uploads/11/White-Tesla-Model-S-PNG-HD-Image.png",
		"battery": "71",
		"mileage": "13000",
		
	}

	let size = props.size ? props.size : "col-md-4"

	return(
		<div className={size + " carcard pointer"} onClick={()=> window.location.href="/CarDetail/1000"}>
			<div className="innercarddetails" style={{backgroundColor: color}}>
				<div className="text text2 boldtext">{data.make}</div>
				<div className="text text125 grey">{data.year} {data.model}</div>

				<div className="maxsizeimgwrapper" style={{marginTop: "20px", marginBottom: "20px"}}>
					<img src={data.image} alt="car" className="maxsizeimg"/>	
				</div>
				{/* <div className="tag" style={{marginBottom: "20px"}}>New</div> */}
				<div className="row">
					<div className="text text175 col-12 text-center">${(parseInt(data.price).toLocaleString() )}</div>
				</div>
				<div className="row justify-content-center" style={{marginTop: "20px"}}>
					<div className="col-3 col-sm-3 centervert centerverthor">
						<i className="fa fa-car" style={{color: "white", marginBottom: "5px"}}></i>
						<span className="text text125 grey">{(parseInt(data.mileage) >= 1000) ? (Math.round(parseInt(data.mileage) / 1000)) + "K" : data.mileage}</span>
					</div>
					<div className="col-3 col-sm-3 centervert centerverthor">
						<i className="fa fa-calendar" style={{color: "white", marginBottom: "5px"}}></i>
						<span className="text text125 grey">{data.year}</span>
					</div>
					<div className="col-3 col-sm-3 centervert centerverthor">
						<i className="fa fa-battery-half" style={{color: "white", marginBottom: "5px"}}></i>
						<span className="text text125 grey">{data.battery}</span>
					</div>
				</div>
				<div className="text text125 viewdetailbutton" >
						View Details &#8594;
					</div>
			</div>
		</div>
	)
}

export default CarCard;